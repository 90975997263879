import React from 'react'
import styled from 'styled-components';

import { 
  LonelyBlue
} from './colors';

// Unfortunately this was all copied around to the other 
// headers...should consolidate
const SiteHeader = styled.h1`
  text-transform: uppercase;
  font-family: "Compacta Bold", Courier;
  // -webkit-text-stroke: 2px ${LonelyBlue};
  letter-spacing: .2rem;
  color: ${LonelyBlue};
  font-size: 3rem !important;
  & > a {
    font-size: 3rem !important;
  }
  padding-top: 1.5rem;
  margin-left: 1.5rem;
  margin-bottom: .25rem;
`;

const Container = styled.div`
  padding-bottom: .25rem;

  & > div > h1 > a {
    @media (min-width: 300px) {
      font-size: smaller;
    }

    margin-left: 1rem;
  }
`;

const Header = ({ siteTitle }) => (
  <Container>
    <div
      style={{
        margin: '0 auto',
        maxWidth: 900,
      }}
    >
      <SiteHeader>
        {siteTitle}
      </SiteHeader>
    </div>
  </Container>
)

export default Header
