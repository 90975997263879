import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
// import { graphql } from 'gatsby';

import Header from '../components/header'
import './index.css'
import '../fonts/compacta-bold.css'

const Layout = ({ children, data }) => (
  <div style={{ 
    minHeight: "100%",
    height: '100%'
    }}
  >
    <Helmet
      title={data.site.siteMetadata.title}
      meta={[
        { name: 'description', content: 'Rustam Kasad is a software engineer and musician living in San Diego' },
        { name: 'keywords', content: 'Rustam Kasad, software development, music' },
        { name: 'google-site-verification',
          content: 'SepR0fhObssJIFJrp68Jd-hpnD2nGpEmlehRr5Qa82c' 
        },
        { name: 'og:title', content: 'Rustam Kasad' },
        { name: 'og:description', content: 'Personal website (software + music)' }
      ]}
    />
    <Header siteTitle={data.site.siteMetadata.title} />
    <div
      style={{
        margin: '0 auto',
        maxWidth: 900,
        padding: '0px 1.0875rem 1.45rem',
        paddingTop: 0,
      }}
    >
      {children}
    </div>
  </div>
)

Layout.propTypes = {
  children: PropTypes.func,
}

export default Layout

// export const query = graphql`
//   query SiteTitleQuery {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `
