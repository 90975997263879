export const YellowHand =  "#fbf579";
export const LonelyBlue = "#005995";
export const StationaryPinkRed = "#fa625f";
export const Purpled = "#600473"

export const DullWhite = '#e4e3dc';
export const SkyBlue = '#459eb9';
export const LightThroneGreen = '#adad8d';
export const Black = '#141311';

export default YellowHand;
